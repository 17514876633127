<template>
	<div class="get_desktop_img_wrapper">
		<b-form-group :label="FormMSG(114, 'Select an image from your computer')" label-for="file-default">
			<b-form-file
				:browse-text="FormMSG(113, 'Browse')"
				:placeholder="FormMSG(114, 'Choose a file from your computer')"
				multiple
				id="file-default"
				@change="onFileSelected"
			/>
		</b-form-group>
	</div>
</template>

<script>
import { isNil } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'GetDesktopImgComponent',
	mixins: [languageMessages],
	data() {
		return {
			selectedImages: []
		};
	},
	watch: {
		selectedImages(val) {
			this.$emit('change', val);
		}
	},
	methods: {
		/**
		 * @param {Object} $event
		 * { target, dataTransfer }
		 */
		onFileSelected($event) {
			const { files } = $event.target;
			if (isNil(files)) return;
			const keys = Object.keys(files);
			keys.forEach(async (k) => await this.createImage(files[k]));
		},

		/**
		 * @param {Object} file
		 */
		createImage(file) {
			// var image = new Image();
			const reader = new FileReader();

			reader.onload = (e) => (this.selectedImages = e.target.result);
			reader.readAsDataURL(file);
		}
	}
};
</script>
